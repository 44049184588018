import moment from 'moment';
import React from 'react';
import {Col, Image, OverlayTrigger} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import {TFunction} from 'react-i18next';
import {AiFillPieChart} from 'react-icons/ai';
import {FaCheckDouble, FaHandshake} from 'react-icons/fa';
import {FaCalendarAlt, FaPencilAlt} from 'react-icons/fa';
import {IAudienceCharts} from '../../app/brand/campaign/book/offer/dto/IAudienceCharts';
import {IChartData} from '../../app/brand/campaign/book/offer/dto/IAudienceDataModal';
import {chartColors} from '../../app/brand/campaign/book/offer/modal/tabs/audience/EnhancedAudienceData';
import {iManageFilterIcons} from '../../app/brand/campaign/manage/calendar/dto/IManageCalendar';
import {IInfluencerDistribution} from '../../app/influencer/offer/dto/IInfluencerDistribution';
import {IPlannerData} from '../../app/influencer/planner/dto/IPlanner';
import {AuthInfo} from '../../authentication/AuthInfo';
import {AgencyInfo} from '../../authentication/register/agency/components/AgencyInfo';
import {StepperHeader} from '../../authentication/register/brand/components/StepperHeader';
import {EmployeeInfo} from '../../authentication/register/employee/components/EmployeeInfo';
import {AuthService} from '../../authentication/service';
import {CampaignUserType} from '../../enum/CampaignUserType';
import {InternalRoutes} from '../../enum/InternalRoutes';
import {PackagesEnum} from '../../enum/PackageEnum';
import history from '../../history';
import {IInviteInfluencer} from '../../model/book/Invites';
import {ICampaignOverview} from '../../model/briefing/overview/ICampaignOverview';
import {IAdditionalItem} from '../../model/offer/IAdditionalItem';
import {IOfferItemsRequest, ITotalPlanned} from '../../model/offer/IOfferItems';
import {ITotalPlatforms} from '../../model/platform/ITotalPlatforms';
import {Platforms} from '../../model/platform/Platforms';
import {IPicture} from '../../model/shared/IPicture';
import {ThreadTypeEnum} from '../../store/enums/enumsRedux';
import {AUTH_AGENCY_URL, AUTH_BRAND_URL} from '../../utils/endpoints';
import {ErrorToast, SuccessToast} from '../../utils/toasters';
import {CustomImageHandler} from '../components/CustomImageHandler';
import {ISupportVideos} from '../layout/components/menu/SupportModal';
import {StoreKeeper} from '../../store/store';
import {AccessRightEnum} from '../../enum/AccessRightEnum';
import {CampaignSharingType} from '../../enum/CampaignSharingTypeEnum';
import {t} from 'i18next';
import {IBudgedRange} from '../../model/briefing/step1/ICreatorTypes';
import {EnvironmentType} from '../../enum/EnvironmentType';
import {AgencyInfluencerInfo} from '../../authentication/register/agency/components/AgencyInfluencerInfo';
import {INotificationRedDots} from '../../store/influencer/redDotsSlice';
import {IMessageNew} from '../../app/brand/campaign/messages/dto/IMessage';

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const convertImageToBase64 = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const hasAccessRights = (key: string) => {
    const state = StoreKeeper.store.getState();
    const accessRights = state?.auth?.user?.accessRights;
    return accessRights.includes(String(key));
};

export function checkCampaignTabsVisibility(tabName: string) {
    switch (tabName) {
        case 'dashboard':
            return hasAccessRights(AccessRightEnum.CAN_VIEW_DASHBOARD)
        case 'book':
            return hasAccessRights(AccessRightEnum.CAN_VIEW_BOOKING)
        case 'manage':
            return hasAccessRights(AccessRightEnum.CAN_VIEW_MANAGE)
        case 'measure':
            return hasAccessRights(AccessRightEnum.CAN_VIEW_MEASURE)
        case 'messages':
            return hasAccessRights(AccessRightEnum.CAN_VIEW_MESSAGE)
        case 'options':
            return hasAccessRights(AccessRightEnum.CAN_VIEW_OPTIONS)
    }
}

export const getPptxUrl = (url: string) => {
    return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
};


export const getCorrectFilterKeys = (currValue: number, isBrand: boolean): string[] | null => {
    switch (currValue) {
        case 0:
            return isBrand ? [ThreadTypeEnum.CLIENT, ThreadTypeEnum.CUSTOMER_SUCCESS] : [ThreadTypeEnum.CONTENT_CREATORS]; // for management
        case 1:
            return isBrand ? [ThreadTypeEnum.CONTENT_CREATORS] : [ThreadTypeEnum.AGENCY_CREATORS]; // for content creators
        default:
            return null;
    }
};

export function isBetween(x: number, min: number, max: number) {
    return x >= min && x <= max;
}

export function getLocalDateWithoutTimezone(date: Date): Date {
    const offsetMilliseconds = date.getTimezoneOffset() * 60 * 1000;
    const localTimeWithoutOffset = date.getTime() - offsetMilliseconds;
    return new Date(localTimeWithoutOffset);
}

export function isDateInPast(date: string): boolean {
    const pastDate = new Date(date);
    const currentDate = new Date();
    return pastDate.getTime() < currentDate.getTime();
}

export function isDateLessThan7Days(date1: Date, date2: Date): boolean {
    const timeDifference = date1.getTime() - date2.getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference < 7;
}

export const cleanUpPayload = (payload: any) => Object.entries(payload).reduce((acc, [key, val]) => {
    if (val) {
        return ({...acc, [key]: val});
    }
    return acc;
}, {});

export function isFileVideo(fileName: string) {
    const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.mkv', '.flv', '.webm'];
    const extension = fileName?.substring(fileName.lastIndexOf('.'));
    return videoExtensions.includes(extension?.toLowerCase()) ?? false;
}

export function getCorrectImageThumbnail(hasExternalLink: boolean, originalName: string, thumbPath: string, path: string) {
    return hasExternalLink ? '/tempAssets/videoPlaceholder.svg' : (isFileVideo(originalName ?? '') ?
        (!!thumbPath.length ? thumbPath : '/tempAssets/videoPlaceholder.svg') : thumbPath ? thumbPath : path ? path : '/icons/imagePlaceholder.png');
}

export function getExternalLink(externalLink: string | undefined) {
    const externalLinkFinal = externalLink && !externalLink.includes('http')
        ? `https://${externalLink}`
        : externalLink;

    return externalLinkFinal
}

export const getCorrectSocialUrl = (name: string, handle: string, externalLink?: string) => {
    switch (name) {
        case 'instagram':
            return 'https://instagram.com/' + handle;
        case 'youtube':
            return 'https://youtube.com/@' + handle;
        case 'facebook':
            return 'https://facebook.com/' + handle;
        case 'tiktok':
            return 'https://tiktok.com/@' + handle;
        case 'twitter':
            return 'https://twitter.com/' + handle;
        case 'twitch':
            return 'https://twitch.com/' + handle;
        case 'podcast':
        case 'blog':
        case 'linkedin':
            return externalLink;
        default:
            return null;
    }
};

export function checkSocialIcon(iconName: string) {
    switch (iconName) {
        case 'facebook':
            return (
                <Image src="/icons/platforms/facebook.svg" alt="facebook-image"/>
            );
        case 'facebook_story':
            return (
                <Image src="/icons/platforms/facebook story.svg" alt="facebook-story-image"/>
            );
        case 'instagram':
            return (
                <Image src="/icons/platforms/instagram.svg" alt="instagram-image"/>
            );
        case 'twitter':
            return (
                <Image src="/icons/platforms/twitter.svg" alt="twitter"/>
            );
        case 'youtube':
            return (
                <Image src="/icons/platforms/youtube.svg" alt="youtube"/>
            );
        case 'youtube_shots':
            return (
                <Image src="/icons/platforms/youtube shots.svg" alt="youtube"/>
            );
        case 'tiktok':
            return (
                <Image src="/icons/platforms/tiktok.svg" alt="tiktok"/>
            );
        case 'instagram_story':
            return (
                <Image src="/icons/platforms/instagram.svg" alt="insta-story-image"/>
            );
        case 'instagram_reels':
            return (
                <Image src="/icons/platforms/instagram.svg" alt="insta-reels-image"/>
            );
        case 'blog':
            return (
                <Image src="/icons/platforms/blog.svg" width={25} alt="blog-image"/>
            );
        case 'podcast':
            return (
                <Image src="/icons/platforms/podcast.svg" alt="podcast-image"/>
            );
        default:
            return 'NO-ICON';
    }
}

export const getCorrectColor = (title: string) => {
    switch (title) {
        case 'all':
            return 'black-dot';
        case 'scheduled':
            return 'info-dot';
        case 'proposed':
            return 'proposed-dot';
        case 'reported':
            return 'success-dot';
        case 'feedback_loop':
            return 'feedback_loop-dot';
        case 'published':
            return 'warning-dot';
        case 'approved':
            return 'primary-dot';
        case 'overdue':
            return 'danger-dot';
        default:
            return 'black-dot';
    }
};

export const getCorrectStatusBackgroundColor = (title: string) => {
    switch (title) {
        case 'all':
            return '#000';
        case 'scheduled':
            return 'var(--info)';
        case 'feedback_loop':
            return '#FFD2A8';
        case 'published':
            return 'var(--warning)';
        case 'reported':
            return 'var(--success)';
        case 'approved':
            return 'var(--primary)';
        case 'proposed':
            return 'var(--secondary)';
        default:
            return 'var(--success)';
    }
};

export const calculateUploadUntil = (numDays: number, date: string) => {
    return moment(moment(date).subtract(numDays, 'days').toISOString()).format('DD/MM/YYYY');
};

export const removeTimeZoneFromDate = (date: string) => {
    return date?.replace(/\.[0-9]{0,4}z/i, '');
};

export const downloadFile = (url: string, originalName: string | undefined) => {
    const xhr = new XMLHttpRequest();
    const fileName = url.split('/').at(-1);
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
        if (xhr.status === 200) {
            const blob = new Blob([xhr.response], {type: 'application/*'});
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = originalName ? originalName : fileName ?? '';
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            }, 0);
        }
    };
    xhr.send();
};

export const getCorrectFilterTitle = (title: string, t: any) => {
    return t(`brand.campaign.manage.${title}`);
};

export function getStatusIcon(status: string, cssClass = 'text-white') {
    const defaultIconSize = 15;
    const iconStyle = {
        width: 15,
        height: 15,
    };

    switch (status) {
        case 'received':
            return <i className={`fi fi-rr-handshake-angle ${cssClass}`} style={iconStyle}/>;
        case 'private':
        case 'negotiate':
        case 'pending':
            return <FaHandshake className={`${cssClass}`} size={defaultIconSize}/>;
        case 'draft':
            return <FaCheckDouble className={`${cssClass}`} size={defaultIconSize}/>;
        case 'declined':
        case 'rejected':
        case 'canceled':
            return <i className={`fi fi-rr-triangle-warning ${cssClass}`} style={iconStyle}/>;
        case 'requested':
        case 'sent':
            return <i className={`fi fi-rr-inbox-out ${cssClass}`} style={iconStyle}/>;
        case 'booked':
            return <i className={`fi fi-rr-book-alt ${cssClass}`} style={iconStyle}/>;
        case 'accepted' :
            return <AiFillPieChart className={`${cssClass}`} size={defaultIconSize}/>;
        case 'completed' :
        case 'published' :
            return <AiFillPieChart className={`${cssClass}`} size={defaultIconSize}/>;
        case 'my_campaigns':
            return <i className={`fi fi-rr-briefcase ${cssClass}`} style={iconStyle}/>;
        case 'customer_success_campaigns':
            return <i className={`fi fi-rr-megaphone ${cssClass}`} style={iconStyle}/>;
        case 'supplier_campaigns':
            return <i className={`fi fi-rr-chart-user ${cssClass}`} style={iconStyle}/>;
        case 'client_campaigns':
            return <i className={`fi fi-rr-building ${cssClass}`} style={iconStyle}/>;
    }
}

export function capitalizeFirstLetter(word: string) {
    return word?.charAt(0)?.toUpperCase() + word.slice(1);
}

export const calculatePastYears = (date: string) => {
    return moment().diff(date, 'years');
};

export function linkify(inputText: string) {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    // Replace URLs starting with http:// or https://
    replacePattern1 = /(https?:\/\/[^\s]+)/gi;
    replacedText = inputText?.replace(replacePattern1, '<a target="_blank" rel="noopener noreferrer" href="$1" >$1</a>');

    // Replace URLs starting with "www." but not already linked
    replacePattern2 = /(^|\s)(www\.[^\s]+)/gi;
    replacedText = replacedText?.replace(replacePattern2, '$1<a target="_blank" rel="noopener noreferrer" href="http://$2">$2</a>');

    // Replace URLs starting with "mailto:"
    replacePattern3 = /(mailto:[^\s]+)/gi;
    replacedText = replacedText?.replace(replacePattern3, '<a href="$1">$1</a>');

    // Replace \n tag with <br>
    replacedText = replacedText.replace(/\n/g, '<br>');

    return replacedText;
}

export const isPlatformActive = (platforms: string[], offerItem: string) => {
    return platforms.findIndex((item) => item === offerItem) !== -1
        ? 'active'
        : '';
};

export const formatDate = (date: Date | string | undefined, format?: string) => {
    return moment(date)?.format(format ?? 'DD.MM.YYYY');
};

export const getSocialIcons = ({
                                   iconPath,
                                   dataTop,
                                   dataDown,
                                   colorText = '',
                                   parentClasses = '',
                                   classes = '',
                                   isTopRight = false,
                                   isCompleted = false,
                               }: any) => {
    return (
        <div className={`d-flex align-items-center  ${parentClasses} `}>
            <Image src={iconPath} className={classes}/>
            {(dataTop || dataDown) && <div className="ml-2">
                <div className={`text-${colorText} mb-0 font-weight-bold`}>{dataTop}</div>
                {<div
                    className={`mb-0 ${isTopRight ? 'position-top-left' : ''} ${(isCompleted && isTopRight) ? 'bg-primary' : (!isCompleted && isTopRight) ? 'bg-danger' : ''}`}>{dataDown}</div>}
            </div>}
        </div>
    );
};


export const gePlatformIcon = ({iconPath, platformName}: any) => {
    return (
        <div className={`d-flex align-items-center `}>
            <Image src={iconPath}/>
            <div className="ml-2">
                <h6 className={`mb-0 text-primary`}>
                    {platformName}
                </h6>
            </div>
        </div>
    )
}

export function getTotals(additionalItems?: IAdditionalItem[], totalPlatforms?: ITotalPlatforms[], isTransparentPricing?: boolean) {
    let totalPrice = 0;
    if (totalPlatforms && additionalItems) {
        totalPlatforms?.forEach(platform => totalPrice += platform[isTransparentPricing ? 'priceWithoutFee' : 'priceWithFee']);
        additionalItems.forEach((item: any) => totalPrice += item[isTransparentPricing ? 'price' : 'price']);
        return Math.round(totalPrice);
    } else if (additionalItems) {
        additionalItems.forEach((item: any) => totalPrice += item[isTransparentPricing ? 'priceWithoutFee' : 'price']);
        return Math.round(totalPrice);
    } else if (totalPlatforms) {
        totalPlatforms?.forEach(platform => totalPrice += platform[isTransparentPricing ? 'priceWithoutFee' : 'priceWithFee']);
        return Math.round(totalPrice);
    }
}

export const getPlannerCardIcons = ({status, offers, t}: any) => {
    return (
        <div key={status}
             className={`col-3 px-1 d-flex flex-column align-items-center justify-content-between`}>
            <div className="d-flex align-items-center">
                <span className={`text-${status === 'scheduled' ? 'info' : 'primary'}`}>
                    {offers?.totalPlanned?.[status as keyof ITotalPlanned] ?? 0}
                    /
                    {offers?.totalPlanned?.totalNbPosts ?? 0}
                </span>
            </div>
            <div className="mt-2">
                {t(`brand.campaign.manage.${status}_post`)}
            </div>
        </div>
    );
};

export function getUniqueListBy<T, K>(arr: T[], getKey: (item: T) => K) {
    return arr.reduce((unique: T[], o: T) => {
        if (!unique.some(obj => getKey(obj) === getKey(o))) {
            unique.push(o);
        }
        return unique;
    }, []);
}

export const getManageFilterIcons = ({status, t, totalPlanned, showManageIcon = true}: iManageFilterIcons) => {
    const totalPosts = totalPlanned?.totalNbPosts ?? '0';
    if (status !== 'proposed') {
        return;
    }
    return (
        <Col xs={3} key={status}
             className={`d-flex flex-column align-items-center align-items-xl-end px-0`}>
            <div className={`d-flex align-items-center ${!showManageIcon ? 'w-100 justify-content-center' : ''}`}>
                {showManageIcon && <div className="position-relative">
                    <FaCalendarAlt
                        className={`text-info font-20`}/>
                    <div
                        className="position-absolute bg-white d-flex align-items-center justify-content-center border"
                        style={{
                            width: 15,
                            height: 15,
                            top: '35%',
                            left: '35%',
                            borderRadius: '50%',
                            boxShadow: '1px 1px 2px #00000026',
                        }}
                    >
                        <FaPencilAlt className="font-12 text-info"/>
                    </div>
                </div>}
                <span
                    className={`${showManageIcon ? 'ml-3' : ''} text-info`}>
                    {totalPlanned?.[status as keyof ITotalPlanned] ?? 0}
                    /
                    {totalPosts}</span>
            </div>
            <div className="my-2 w-100 text-center">
                {t(`brand.campaign.manage.tasks`)}
            </div>
        </Col>
    );
};

export const isFileImage = (pathname: string) => {
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp', 'jfif'];
    const extension = pathname?.split('.')?.at(-1);
    const fileExtension = (allowed: string) => extension === allowed;
    return allowedExtensions.some(fileExtension);
};

export const oneStepUp = (campaignId: number, isBriefingComplete: boolean, briefingStep: string) => {
    if (!isBriefingComplete) {
        history.push(`/brand/create/campaign/${campaignId}/step${briefingStep}`);
    } else {
        history.push(`/brand/create/campaign/${campaignId}/overview`);
    }
};

export function clearHtmlFromString(html: any) {
    return new DOMParser().parseFromString(html, 'text/html').body.textContent?.trim() || '';
}

export const numberRange = (currNum: number, min: number = 1, max: number = 30) => {
    return Math.min(Math.max(Number(currNum), min), max) as unknown as string;
};

export const getCorrectRegisterImage = (step: string, isRegister: boolean) => {
    const baseRoute = '/tempAssets/registerImages/';
    return isRegister ? `${baseRoute}register${step}.png` : '/tempAssets/img-login.jpeg';
};

export const calculatePastMonths = (date: string | null) => {
    return moment(new Date().toISOString()).diff(moment(date), 'months');
};

export const getPackageIcon = (currPackage: string | undefined, t: TFunction) => {
    return (
        <div className="d-flex align-items-center">
            <img src={`/icons/packages/${currPackage}.svg`}
                 width={30}
                 height={30}
                 alt={`${currPackage} package icon`}/>
            <h5 className="mb-0 text-uppercase ml-2">{t(`brand.package.${currPackage}`)}</h5>
        </div>
    )
}
export const getPackageColor = (currPackage: string | undefined) => {
    switch (currPackage) {
        case PackagesEnum.BASIC:
            return 'warning';
        case PackagesEnum.PRO:
            return 'primary';
        default:
            return 'info';
    }
};
export const getCorrectRegisterIcon = (step: string, currentScreen: string) => {
    const baseRoute = '/icons/profile/';
    if (currentScreen === 'registerInfluencer') {
        return getCorrectInfluencerIcon(step, baseRoute);
    } else if (currentScreen === 'registerBrand' || currentScreen === 'registerAgency') {
        return getCorrectBrandRegisterIcon(step, baseRoute);
    } else if (currentScreen === 'registerEmployee') {
        return getCorrectEmployeeRegisterIcon(step, baseRoute);
    }
};

export const getCorrectBrandRegisterIcon = (step: string, baseRoute: string) => {
    switch (step) {
        case '1':
            return baseRoute + 'id-card.png';
        case '2':
            return baseRoute + 'verified.png';
        case '3':
            return baseRoute + 'detail-card.png';
        case '4':
            return baseRoute + 'business-and-trade.png';
        case '5':
            return baseRoute + 'employees.png';
        case '6':
            return baseRoute + 'user-role.png';
        case '7':
            return baseRoute + 'firewall.png';
        case '8':
            return baseRoute + 'online-shopping.png';
        default:
            return null;
    }
};

export const getCorrectEmployeeRegisterIcon = (step: string, baseRoute: string) => {
    switch (step) {
        case '1':
            return baseRoute + 'id-card.png';
        case '2':
            return baseRoute + 'verified.png';
        case '3':
            return baseRoute + 'detail-card.png';
        case '4':
            return baseRoute + 'firewall.png';
        default:
            return null;
    }
};

export const getCorrectInfluencerIcon = (step: string, baseRoute: string) => {
    switch (step) {
        case '1':
            return baseRoute + 'id-card.png';
        case '2':
            return baseRoute + 'verified.png';
        case '3':
            return baseRoute + 'img-social-media.png';
        case '4':
            return baseRoute + 'user.png';
        case '5':
            return baseRoute + 'img-categories.png';
        case '6':
            return baseRoute + 'online-shopping.png';
        case '7':
            return baseRoute + 'firewall.png';
        case '8':
            return baseRoute + 'employees.png';
        default:
            return null;
    }
};

export const getCorrectRegisterInfo = (currentScreen: string) => {
    switch (currentScreen) {
        case 'login':
        case 'registerBrand':
        case 'registerInfluencer':
            return <AuthInfo isRegister={currentScreen !== 'login'}/>;
        case 'registerAgency':
            return <AgencyInfo/>;
        case 'registerAgencyInfluencer':
            return <AgencyInfluencerInfo/>;
        case 'registerEmployee':
            return <EmployeeInfo/>;
        default:
            return null;
    }
};

export const getCorrectRegisterStepper = (currentScreen: string, currentStepId: string, maxSteps?: number) => {
    switch (currentScreen) {
        case 'registerInfluencer':
            return <StepperHeader currentStepId={currentStepId} maxSteps={maxSteps ?? 0}
                                  currentScreen={currentScreen}/>;
        case 'registerAgency':
            return <StepperHeader currentStepId={currentStepId} maxSteps={6} currentScreen={currentScreen}/>;
        case 'registerBrand':
            return <StepperHeader currentStepId={currentStepId} maxSteps={5} currentScreen={currentScreen}/>;
        case 'registerEmployee':
            return <StepperHeader currentStepId={currentStepId} maxSteps={4} currentScreen={currentScreen}/>;
        default:
            return null;
    }
};

export const getCorrectRegisterEmployeeText = (step: string, t: TFunction, email?: string) => {
    switch (step) {
        case '1':
            return {
                title: t('auth.register.employee.step1.brandAccount'),
                description: t('auth.register.employee.step1.brandAccountDescription'),
            };
        case '2':
            return {
                title: t('auth.register.brand.step2.verify'),
                description: t('auth.register.brand.step2.verifyDescription', {
                    emailAddress: email,
                }),
            };
        case '3':
            return {
                title: t('auth.register.brand.step3.details'),
                description: t('auth.register.brand.step3.detailsDescription'),
            };
        case '4':
            return {
                title: t('auth.register.brand.step7.globalPreferences'),
                description: t('auth.register.brand.step7.globalPreferencesDescription'),
            };
        default:
            return null;
    }
};

export const getVerifyRoute = (currentScreen: string) => {
    switch (currentScreen) {
        case 'registerBrand':
        case 'registerEmployee':
            return AUTH_BRAND_URL;
        case 'registerAgency':
            return AUTH_AGENCY_URL;
        default:
            return 'NO URL';
    }
};

export const getCorrectRegisterAgencyText = (step: string, t: TFunction, email?: string) => {
    switch (step) {
        case '1':
            return {
                title: t('auth.register.brand.step1.adminAccount'),
                description: t('auth.register.agency.step1.adminAccountDescription'),
            };
        case '2':
            return {
                title: t('auth.register.brand.step2.verify'),
                description: t('auth.register.brand.step2.verifyDescription', {
                    emailAddress: email,
                }),
            };
        case '3':
            return {
                title: t('auth.register.brand.step3.details'),
                description: t('auth.register.brand.step3.detailsDescription'),
            };
        case '4':
            return {
                title: t('auth.register.agency.step4.creatorInfo'),
                description: t('auth.register.agency.step4.creatorInfoDescription'),
            };
        case '5':
            return {
                title: t('auth.register.brand.step5.billingInformations'),
                description: t('auth.register.brand.step5.billingInformationsDescription'),
            };
        case '6':
            return {
                title: t('auth.register.brand.step7.globalPreferences'),
                description: t('auth.register.brand.step7.globalPreferencesDescription'),
            };

        case '7':
            return {
                title: t('auth.register.brand.step6.inviteTeam'),
                description: t('auth.register.brand.step6.inviteTeamDescription'),
            };
        case '8':
            return {
                title: t('auth.register.agency.step8.finish'),
                description: t('auth.register.brand.step8.finishDescription'),
            };
        default:
            return null;
    }
};

export const getCorrectRegisterBrandText = (step: string, t: TFunction, email?: string) => {
    switch (step) {
        case '1':
            return {
                title: t('auth.register.brand.step1.adminAccount'),
                description: t('auth.register.brand.step1.adminAccountDescription'),
            };
        case '2':
            return {
                title: t('auth.register.brand.step2.verify'),
                description: t('auth.register.brand.step2.verifyDescription', {
                    emailAddress: email,
                }),
            };
        case '3':
            return {
                title: t('auth.register.brand.step4.companyInfo'),
                description: t('auth.register.brand.step4.companyInfoDescription'),
            };
        case '4':
            return {
                title: t('auth.register.brand.step5.billingInformations'),
                description: t('auth.register.brand.step5.billingInformationsDescription'),
            };
        case '5':
            return {
                title: t('auth.register.brand.step8.finish'),
                description: t('auth.register.brand.step8.finishDescription'),
            };
        default:
            return null;
    }
};

export const updateRegistrationStep = (stepId: number, token: string) => {
    return AuthService.updateCurrentStep({
        registrationStep: +stepId,
        verificationUUID: token,
    }).then(response => {
    }).catch(error => ErrorToast(error));
};

export const getCorrectRegisterInfluencerText = (step: string, t: TFunction, email?: string) => {
    switch (step) {
        case '1':
            return {
                title: t('auth.register.influencer.step1.user-data'),
                description: t('auth.register.influencer.step1.login_credentials'),
            };
        case '2':
            return {
                title: t('auth.register.influencer.step2.verify'),
                description: t('auth.register.influencer.step2.verifyDescription', {
                    emailAddress: email,
                }),
            };
        case '3':
            return {
                title: t('auth.register.influencer.step3.social_media_network'),
                description: t('auth.register.influencer.step3.automatic_data'),
            };
        case '4':
            return {
                title: t('auth.register.influencer.step4.personal_info'),
                description: t('auth.register.influencer.step4.personal_desc'),
            };
        case '5':
            return {
                title: t('auth.register.influencer.step5.categories'),
                description: t('auth.register.influencer.step5.chose_categories'),
            };
        case '6':
            return {
                title: t('auth.register.influencer.step6.billing_info'),
                description: t('auth.register.influencer.step6.billing_info_desc'),
            };
        case '7':
            return {
                title: t('auth.register.brand.step7.globalPreferences'),
                description: t('auth.register.brand.step7.globalPreferencesDescription'),
            };
        case '8':
            return {
                title: t('auth.register.influencer.step7.finish'),
                description: t('auth.register.influencer.step7.finishDescription'),
            };
        default:
            return null;
    }
};

export const getCorrectRegisterRoute = (screen: string) => {
    switch (screen) {
        case 'registerBrand':
            return '/brand/register';
        case 'registerEmployee':
            return '/brand/employee/register';
        case 'registerAgencyInfluencer':
        case 'registerInfluencer':
            return InternalRoutes.INFLUENCER_REGISTRATION;
        case 'registerAgency':
            return InternalRoutes.AGENCY_REGISTRATION;
        default:
            return null;
    }
};

export function getAdditionalItemIcon(name: string) {
    switch (name) {
        case 'branded_content':
            return <CustomImageHandler photoPath="/icons/additionalItems/img-branded-content.svg" altTag={name}
                                       classes="social-icon"/>;
        case 'exclusivity':
            return <CustomImageHandler photoPath="/icons/additionalItems/img-exclusivity.svg" altTag={name}
                                       classes="social-icon"/>;
        case 'usage_right':
            return <CustomImageHandler photoPath="/icons/additionalItems/img-usage-right.svg" altTag={name}
                                       classes="social-icon"/>;
        case 'bylaws':
            return <CustomImageHandler photoPath="/icons/additionalItems/img-bylaws.png" altTag={name}
                                       classes="social-icon"/>;
    }
}

export function truncateString(str: string, n: number) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
}

export const getPlatformIcon = (totalPlanned: ITotalPlanned, status: keyof ITotalPlanned) => {
    return (
        <div
            className={`my-2 text-center ${!!getCampaignNumbers(status, totalPlanned) ? 'opacity-100' : 'opacity-25'}`}>
            {status === 'overdue' ? <img src="/icons/tick-mark.png" alt="tick-mark icon"/> :
                <img src="/icons/check.png" alt="Check icon"/>}
        </div>
    );
};

export const getCampaignNumbers = (status: keyof ITotalPlanned, totalPlanned: ITotalPlanned) => {
    return totalPlanned?.[status] ?? 0;
};

export function getFirstPreviewImage(key: string, campaignImages?: IPicture[], classes?: string) {
    const firstImage = campaignImages?.filter(itemImage => itemImage?.purpose === key)?.shift();
    if (firstImage?.id) {
        return <CustomImageHandler altTag={firstImage?.name ?? ''}
                                   key={firstImage?.id}
                                   classes={classes ? classes : 'moonboard-images'}
                                   thumbnailPath={firstImage?.thumbnail ?? ''}
                                   photoPath={firstImage?.thumbnail ?? ''}
        />;
    } else {
        return <img className={classes ? classes : 'moonboard-images'}
                    src="/icons/imagePlaceholder.png"
                    alt="campaign-img"/>;
    }
}

export function sortByPlatform(arr: Platforms[]) {
    return arr.sort(function (a, b) {
        const platformA = a.platform.toUpperCase(); // ignore upper and lowercase
        const platformB = b.platform.toUpperCase(); // ignore upper and lowercase
        if (platformA < platformB) {
            return -1;
        }
        if (platformA > platformB) {
            return 1;
        }

        // platforms are equal
        return 0;
    });
}

export function getPostsPerPlatform(offerItems: IOfferItemsRequest[], mainPlatform: number) {
    const filteredOffers = offerItems.filter((item) => item.mainPlatformId === mainPlatform);
    return filteredOffers.reduce((acc, item) => acc + (item.nbPosts), 0);
}

export function getNumberPosts(totalPlatforms?: ITotalPlatforms[]) {
    return totalPlatforms?.reduce((acc, item) => acc + (item.nbPostsTotal), 0);
}

export function getNumberContentPieces(totalPlatforms?: IOfferItemsRequest[]) {
    return totalPlatforms?.reduce((acc, item) => acc + (item.nbPosts), 0);
}

export function getPricePerPlatform(offerItems: IOfferItemsRequest[], mainPlatform: number) {
    const filteredOffers = offerItems.filter((item) => item.mainPlatformId === mainPlatform);
    const platformSum = filteredOffers.reduce((acc, item) => acc + (item.price), 0);
    return platformSum;
}

export function getOldPricePerPlatform(offerItems: IOfferItemsRequest[], mainPlatform: number) {
    const filteredOffers = offerItems.filter((item) => item.mainPlatformId === mainPlatform);
    const platformSum = filteredOffers.reduce((acc, item) => acc + (item?.oldPrice ?? 0), 0);
    return platformSum;
}

export function getCpmValue(offerItems: IOfferItemsRequest[], platform: ITotalPlatforms) {
    // Price Platform / (Average Reach x Number of Placements )) x 1000;
    const price = getPricePerPlatform(offerItems, platform?.id ?? 0);
    const cpmSum = (price ?? 1) / (calculateAvgReach(offerItems, platform)) * 1000;
    return isNaN(cpmSum) ? 0 : Math.round(cpmSum);
}

export const calculateAvgReach = (offerItems: IOfferItemsRequest[], platform: ITotalPlatforms) => {
    const nbPostsTotal = getPostsPerPlatform(offerItems, platform?.id ?? 0);

    const isModashData = ['Youtube', 'Tiktok'].includes(platform.name);
    if (isModashData) {
        return platform?.averageViews * nbPostsTotal;
    }
    return ((platform?.totalFollowers ?? 0) * 0.25) * (nbPostsTotal ?? 1);
};

export function getPriceOneContent(offerItems: IOfferItemsRequest[], socialPlatformId: number) {
    const platform = offerItems.find((item) => item.socialPlatformId === socialPlatformId);
    let price = 0;
    if (platform) {
        if (platform.nbPosts <= 0 || platform.price <= 0) {
            return 0;
        }
        price = platform.price / platform.nbPosts;
    }
    return Math.round(price);
}

export const calculateTotalReach = (campaign: ICampaignOverview | undefined, initialOffer: IInviteInfluencer) => {
    if (campaign) {
        return campaign?.offerPlaceholder?.totalPlatforms?.map(platform => {
            return Number(calculateAvgReach(initialOffer.offerItems, platform) ?? 0);
        })?.reduce((partialSum, a) => partialSum + a, 0); // sum all values from array
    }
};

export function getSubtotalAdditionalItems(distribution: IInfluencerDistribution[]) {
    const additionalSubtotal = distribution.reduce((acc, item) => acc + (Math.round(item.price)), 0);
    return Math.round(additionalSubtotal);
}

export function getSubtotalOfferItems(offerItems: IOfferItemsRequest[]) {
    const offerItemsSubtotal = offerItems.reduce((acc, item) => acc + (Math.round(item.price)), 0);
    return Math.round(offerItemsSubtotal);
}

export function getGrandTotal(offerItems: IOfferItemsRequest[], distribution: IInfluencerDistribution[]) {
    const offerItemsSubtotal = offerItems.reduce((acc, item) => acc + (Math.round(item.price)), 0);
    const additionalSubtotal = distribution.reduce((acc, item) => acc + (Math.round(item.price)), 0);
    const sum = offerItemsSubtotal + additionalSubtotal;
    return Math.round(sum);
}

export const checkNumberPosts = (socialId: number, initialNumberPosts: number, offerItems: IOfferItemsRequest[]) => {
    const selectedPlatform = offerItems?.find(offerItem => offerItem.socialPlatformId === socialId);
    if (selectedPlatform) {
        return selectedPlatform.nbPosts ?? 0;
    }
    return initialNumberPosts;
};

export const groupedTutorialData = (data: ISupportVideos[], selectedKey: keyof ISupportVideos) => data?.reduce((result: any, item: any) => {
    const key = item[selectedKey];
    if (!result[key]) {
        result[key] = [];
    }
    result[key].push(item);
    return result;
}, {});

export function calculateRecommendedBudgetRange(budgetRanges: IBudgedRange[], brandedContentBudget: number) {
    if (!!budgetRanges?.length) {
        return brandedContentBudget + (Number(budgetRanges[0]?.recommendedMinBudget ?? 0) + Number(budgetRanges[0]?.recommendedMaxBudget ?? 0)) / 2;
    }
}

export function groupByCampaignId(array: IPlannerData[]) {
    let grouped = {};
    array.forEach(offer => {
        let campaignId = Number(offer?.campaign?.id);
        if (!grouped[campaignId as keyof typeof grouped]) {
            // @ts-ignore
            grouped[campaignId] = []
        }
        // @ts-ignore
        grouped[campaignId].push(offer);
    })
    return grouped;
}

export const removeNumFromString = (url: string) => {
    return url.split('/').filter(b => !+b).join('/');
}
export const getCountriesArray = (countries: string[], t: any, nbVisibleCountries?: number) => {
    return countries ? `${countries?.slice(0, nbVisibleCountries ?? 1).map(item => t(`general.countries.${item}`)).join(', ')}` : '';
};

export const countriesTooltip = (countries: string[], t: any, placement: Placement | undefined = 'right') => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip id={`tooltip-right`}>
                    {countries?.map(item => t(`general.countries.${item}`)).join(', ')}
                </Tooltip>
            }
        >
            <span className="cursor-pointer"> + {countries.length}</span>
        </OverlayTrigger>
    );
};
export const checkPrice = (socialId: number, initialPrice: number, offerItems: IOfferItemsRequest[]) => {
    const selectedPlatform = offerItems?.find(offerItem => offerItem.socialPlatformId === socialId);
    if (selectedPlatform) {
        return Math.round(selectedPlatform.price ?? 0);
    }
    return Math.round(initialPrice);
};

export const getCommissionFee = (fee: number) => {
    const commissionFee = (1 - fee) * 100;
    return commissionFee.toFixed(2);
};

export const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
};

export const openCalendlyPopup = (url: string) => {
    // @ts-ignore
    if (window.Calendly) {
        // @ts-ignore
        window.Calendly.initPopupWidget({
            url,
        });
    }
};

export async function copyToClipboard(text: string) {
    try {
        await navigator.clipboard.writeText(text);
        SuccessToast('Successfully copied');
    } catch (e) {
        /* ❌ Failed to copy (insufficient permissions) */
        ErrorToast('Failed to copy');
    }
}

export function getFilteredChartsData(chartData: IChartData[]) {
    const allChartsData: IAudienceCharts = {
        engagementChart: [],
        followersChart: [],
        followingChart: [],
        likesChart: []
    };
    chartData?.forEach((item, index) => {
        allChartsData.followingChart.push({
            data: item.following,
            label: item.label,
            borderColor: chartColors[index],
            backgroundColor: chartColors[index],
        });
        allChartsData.followersChart.push({
            data: item.followers,
            label: item.label,
            borderColor: chartColors[index],
            backgroundColor: chartColors[index],
        });
        allChartsData.likesChart.push({
            data: item.likes,
            label: item.label,
            borderColor: chartColors[index],
            backgroundColor: chartColors[index],
        });
        allChartsData.engagementChart.push({
            data: item.engagementRate,
            label: item.label,
            borderColor: chartColors[index],
            backgroundColor: chartColors[index],
        });
    });
    return allChartsData
}

export const returnCampaignType = (isSupervisor: boolean, isGeneralClient: boolean, hasClientCampaigns: boolean) => {
    if (hasClientCampaigns) {
        return CampaignUserType.SUPPLIER_CAMPAIGNS;
    } else if (isGeneralClient) {
        return CampaignUserType.CLIENT_CAMPAIGNS;
    } else if (isSupervisor) {
        return CampaignUserType.CUSTOMER_SUCCESS;
    } else {
        return CampaignUserType.MY_CAMPAIGNS;
    }
};

export function getSharingTypeIcon(type: string, hasName = false, className?: string) {
    if (type === CampaignSharingType.SUPPLIER) {
        return <div className="text-center">
            <i className={`fi fi-rr-building text-primary ${className}`}/>
            {hasName && <h6>{t(`brand.campaign.briefing.${type}`)}</h6>}
        </div>
    } else if (type === CampaignSharingType.CLIENT) {
        return <div className="text-center">
            <i className={`fi fi-rr-chart-user text-primary ${className}`}/>
            {hasName && <h6>{t(`brand.campaign.briefing.${type}`)}</h6>}
        </div>
    } else if (type === CampaignSharingType.OWN_MANAGEMENT) {
        return <div className="text-center">
            <i className={`fi fi-rr-briefcase text-primary ${className}`}/>
            {hasName && <h6>{t(`brand.campaign.briefing.${type}`)}</h6>}
        </div>
    }
}

export const validateVAT = (value: string) => {
    if (value && (value.length < 8 || value.length > 16)) {
        return false;
    }

    // Check if there are any special characters
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
        return false;
    }

    return true;
};

export const rootNavigateWithState = (route: string, state: number) => {
    history.push(route, {
        campaignId: state,
    });
};

export const getNotificationNumber = (tabKey: string, redDots: INotificationRedDots) => {
    const dotMap: { [key: string]: number } = {
        'barterOffers': Number(redDots?.barterOffers || 0),
        'paidOffers': Number(redDots?.paidOffers || 0),
    };

    return dotMap[tabKey ?? ''] || 0;
};

export function changeFileHandler(
    event: React.ChangeEvent<HTMLInputElement>,
    setNewData: React.Dispatch<React.SetStateAction<IMessageNew | undefined>>) {
    const files = event.target.files;
    const validExtensions = [
        'pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff'
    ];
    const invalidFiles = [];

    if (files) {
        for (let i = 0; i < files.length; i++) {
            const fileExtension = files[i].name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !validExtensions.includes(fileExtension)) {
                invalidFiles.push(files[i].name);
            }
        }

        if (invalidFiles.length > 0) {
            ErrorToast({response: {data: {message: `Invalid file types: ${invalidFiles.join(', ')}`}}})
            event.target.value = '';
        } else {
            setNewData((prev) => ({
                ...prev,
                [event.target.name]: files
            }));
        }
    }

}
