import React, {useState} from 'react';
import {Button, Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Campaign} from '../../../../../model/campaign/Campaign';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../../shared/components/placeholder/EmptyState';
import {truncateString} from '../../../../../shared/functions/Functions';
import {MessagesIcon, PlannerIcon, ReportingIcon} from '../../../../../shared/Icons';
import {priceSplitter} from '../../../../../utils/helpers/priceSplitter';
import {InfluencerOfferModal} from '../../../offer/modal/InfluencerOfferModal';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';
import {gtmService} from '../../../../../utils/gtm.service';
import {AgencyInfluencerOfferModal} from '../../../../agency/offer/modal';
import {setSelectMatchedUser} from '../../../../../store/agency/agencyOfferSlice';
import {User} from '../../../../../model/user/User';

interface Props {
    campaigns: Campaign[];
}

const TableView = ({campaigns}: Props) => {
    const history = useHistory();
    const {t} = useTranslation();
    const [campaignId, setCampaignId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showAgencyModal, setShowAgencyModal] = useState(false);
    const dispatch = useDispatch();
    const {
        user: {
            userType,
        }
    } = useSelector((reduxState: IAllStates) => reduxState.auth);
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);

    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);

    const navigateHandler = (route: string, campaignId: number) => {
        history.push(route, {
            campaignId: campaignId
        })
    }

    const openOfferModal = (campaignId: number) => {
        setCampaignId(campaignId)
        if (isAgency) {
            setShowAgencyModal(true);
            dispatch(setSelectMatchedUser(selectedInfluencer));
        } else {
            setShowModal(true);
        }
    };

    const navigateToCampaignOverview = (id: number) => {
        isAgency ?
            history.push(`/agency/campaign/${id}/overview`) :
            history.push(`/influencer/campaign/${id}/overview`);
        gtmService('select_content');
    };


    return (
        <>
            <InfluencerOfferModal show={showModal} setShowModal={setShowModal} campaignId={String(campaignId)}
                                  updateList={() => {
                                  }}/>
            <AgencyInfluencerOfferModal show={showAgencyModal} setShowModal={setShowAgencyModal}
                                        campaignId={String(campaignId)}/>
            {!!campaigns?.length ?
                <div className="myCampaigns">
                    <Table responsive>
                        <thead>
                        <tr className="text-center">
                            <th>{t('general.campaign')}</th>
                            <th>{t('general.brand')}</th>
                            <th>{t('influencer.explore.contentPieces')}</th>
                            <th>{t('brand.campaign.book.offer.modal.price')}</th>
                            <th>{t('general.status')}</th>
                            <th>{t('general.offers')}</th>
                            <th>To Dos</th>
                        </tr>
                        </thead>
                        <tbody>
                        {campaigns?.map((campaign) => (<tr key={campaign?.id}>
                            <td className="text-left cursor-pointer"
                                onClick={() => navigateToCampaignOverview(campaign?.id)}>
                                <div className="d-flex align-items-center cursor-pointer pr-3">
                                    <CustomImageHandler
                                        photoPath={campaign?.campaignImages?.[0]?.path}
                                        altTag="Campaign Logo"
                                        classes="mr-2 default-radius img-cover"/>
                                    <h6 className="font-weight-bold">
                                        {truncateString(campaign?.title ?? '-', 30)}
                                    </h6>
                                </div>
                            </td>
                            <td>
                                {campaign?.company?.displayName ?? '-'}
                            </td>
                            <td>
                                {campaign?.offers?.[0]?.totalNbPosts ?? 0}
                            </td>
                            <td className="euro-symbol">
                                {priceSplitter(Math.round(campaign?.offers?.[0]?.price ?? 0))}
                            </td>
                            <td>
                       <span className={`status ${campaign?.status}`}>
                           {t(`general.campaign_status.${campaign?.status ?? campaign?.offers[0]?.status}`)}
                       </span>
                            </td>
                            <td>
                                <Button variant="outline-primary"
                                        onClick={() => openOfferModal(campaign?.id)}>
                                    {t('general.button.show')}
                                </Button>
                            </td>
                            <td className="w-25">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div
                                        onClick={() => navigateHandler(isAgency ? '/agency/planner' : '/influencer/planner', campaign?.id)}
                                        className="cursor-pointer card py-3 d-flex align-items-center flex-column justify-content-center position-relative">
                                        <PlannerIcon width="20" height="20" color="#9ea5ab"/>
                                        <span className="font-10">
                                    {t('influencer.campaign.tabs.planner')}
                                </span>
                                        {campaign?.offers?.[0]?.redDots?.plannerDot &&
                                            <div id="bullet" className="offer-bullet dashboard-dot"/>}

                                    </div>
                                    <div
                                        onClick={() => navigateHandler(isAgency ? '/agency/reporting' : '/influencer/reporting', campaign?.id)}
                                        className="cursor-pointer card py-3 mx-2 d-flex align-items-center flex-column justify-content-center position-relative">
                                        <ReportingIcon width="20" height="20" color="#9ea5ab"/>
                                        <span className="font-10">
                                    {t('influencer.campaign.tabs.reporting')}
                                </span>
                                        {campaign?.offers?.[0]?.redDots?.reportingDot &&
                                            <div id="bullet" className="offer-bullet dashboard-dot"/>}

                                    </div>
                                    <div
                                        onClick={() => navigateHandler(isAgency ? '/agency/messages' : '/influencer/messages', campaign?.id)}
                                        className="cursor-pointer card py-3 d-flex align-items-center flex-column justify-content-center position-relative">
                                        <MessagesIcon width="20" height="20" color="#9ea5ab"/>
                                        <span className="font-10">
                                    {t('influencer.campaign.tabs.messages')}
                                </span>
                                        {campaign?.offers?.[0]?.redDots?.messagesDot &&
                                            <div id="bullet" className="offer-bullet dashboard-dot"/>}
                                    </div>
                                </div>
                            </td>
                        </tr>))}
                        </tbody>
                    </Table>
                </div> : <div className="card mt-3">
                    <div className="card-body">
                        <EmptyState/>
                    </div>
                </div>
            }
        </>

    );
};

export default TableView;
