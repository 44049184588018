import {TextField} from '@material-ui/core';
import React, {SetStateAction, useState} from 'react';
import {Button, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsFillTrashFill, BsPencilFill} from 'react-icons/bs';
import {FaDownload} from 'react-icons/fa';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {formatDate, getCorrectImageThumbnail, getExternalLink} from '../../../../shared/functions/Functions';
import {AGENCY_PLANNER} from '../../../../utils/endpoints';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {iManageAttachments, iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import UploadingModal from '../../../influencer/planner/components/UploadingModal';
import {InfluencerReportingService} from '../../../influencer/reporting/service';
import {AgencyPlannerService} from '../service';
import {downloadFileAPI} from "../../../../utils";
import FilePlaceholder from '../../../../shared/components/placeholder/FilePlaceholder';

export interface IExternalLink {
    link: string;
    id: number;
}

interface IPreviewContentFilesProps {
    state: iManageList;
    filesToUpload: Blob[] | MediaSource[];
    setFilesToUpload: React.Dispatch<SetStateAction<Blob[] | MediaSource[]>>;
    selectedFile: iManageAttachments;
    setSelectedFile: React.Dispatch<SetStateAction<iManageAttachments | undefined>>;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    isFileUploading: boolean;
    setUpdateReports?: React.Dispatch<SetStateAction<boolean>>;
    setIsFileUploading: React.Dispatch<SetStateAction<boolean>>;
    uploadData: IProgressEvent;
    cancelApiCall: () => void;
}

export const initialExternalState = {
    link: '',
    id: 0
}

export interface IProgressEvent {
    loaded: number;
    from: number;
}

const PreviewContentFiles = ({
                                 state,
                                 setState,
                                 uploadData,
                                 filesToUpload,
                                 setFilesToUpload,
                                 selectedFile,
                                 setSelectedFile,
                                 setUpdateReports,
                                 isFileUploading,
                                 setIsFileUploading,
                                 cancelApiCall,
                             }: IPreviewContentFilesProps) => {
    const {t} = useTranslation();
    const [showInputField, setShowInputField] = useState(false);
    const [externalLink, setExternalLink] = useState<IExternalLink>(initialExternalState);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, id} = event.target;
        setExternalLink(prev => ({...prev, link: value, id: +id}))
    }

    const submitAttachmentHandler = (isUpdate = false) => {
        if (isUpdate) {
            InfluencerReportingService.updateExternalLink(externalLink.id, externalLink.link).then(response => {
                if (response?.id) {
                    setExternalLink(initialExternalState);
                    setShowInputField(false);
                    SuccessToast(t('general.toasts.successUpdated'));
                    setState(response);
                }
            }).catch(error => ErrorToast(error));
        } else {
            InfluencerReportingService.createExternalLink(externalLink?.id, externalLink?.link).then(response => {
                if (response?.id) {
                    setState(prev => ({...prev, attachments: response?.attachments}));
                    setShowInputField(false);
                    SuccessToast(t('general.toasts.successUpdated'))
                    setExternalLink(initialExternalState);
                }
            }).catch(error => ErrorToast(error));
        }
    }

    const deleteImageHandler = async () => {
        await AgencyPlannerService.deleteAttachment(selectedFile?.id as number).then(response => {
            setState(prevState => ({...prevState, attachments: response?.attachments}));
            setUpdateReports && setUpdateReports(prev => !prev);
            SuccessToast(`${t('influencer.planner.successDeletedFile')} ${selectedFile?.originalName ?? selectedFile?.name ?? selectedFile?.externalLink}`);
        }).catch(error => ErrorToast(error));
    };

    const handleCancelAttachmentHandler = () => {
        setShowInputField(false);
        setExternalLink(initialExternalState);
    }

    const isDisabled = ['reported', 'approved'].includes(state?.status);
    return (
        <>
            <UploadingModal show={isFileUploading} uploadData={uploadData} closeModal={() => setIsFileUploading(false)}
                            cancelApiCall={cancelApiCall}/>
            <div className="col-12 mt-3 mb-2">
                <h5>
                    {t('influencer.planner.contentFiles')}
                    {/* {t('influencer.reporting.yourContent')} */}
                </h5>
            </div>
            {!isDisabled && (
                <>
                <div className="col-md-5 mb-md-3 mb-2">
                    <UploadImage
                        saveFile={setFilesToUpload as React.Dispatch<SetStateAction<Blob[] | MediaSource[] | undefined>>}
                        classes="px-0" numberOfFiles={filesToUpload?.length}
                        disabled={isDisabled}
                        isSmall={true}
                        acceptedFormats="video/*, image/jpeg, image/png, image/jpg, application/pdf, audio/mpeg, audio/x-m4a"
                        maxSize={Number(process.env.REACT_APP_PLANNER_MAX_FILE_SIZE)}
                        isMultiple={true}/>
                </div>
                <div className={`col-md-1 d-flex justify-content-center ${showInputField ? 'invisible' : ''}`}>
                    <span className="text-muted mb-md-4 mt-md-5">OR</span>
                </div>
                <div className={`col-md-6 pl-md-0 my-1 ${showInputField ? 'invisible' : ''}`}>
                    <div className="d-flex align-items-center">
                        <TextField
                            label="External Link"
                            variant="outlined"
                            size="small"
                            name="link"
                            id={String(state?.id)}
                            disabled={isDisabled}
                            value={externalLink?.link ?? ''}
                            onChange={changeHandler}
                            className="w-100"
                        />
                        <Button variant="primary" className="ml-1" disabled={isDisabled || !externalLink?.link}
                                onClick={() => submitAttachmentHandler(false)}>
                            {t('general.button.create')}
                        </Button>
                    </div>
                </div>
                </>
            )}

            {state?.attachments?.map((file, index) => {
                return (<div className="col-md-4 mb-3 mt-3" key={file?.id}>
                    <div className={`content-files-card border-${file?.isApproved ? 'primary' : 'warning'}`}>
                        <Row>
                            <div className="col-4">
                                {!!file?.externalLink ? (
                                    <a href={getExternalLink(file?.externalLink)} target='_blank' className="cursor-pointer">
                                        <CustomImageHandler
                                            photoPath={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                                            altTag="Event"
                                            thumbnailPath={file?.thumbnail}
                                            placeholder={getCorrectImageThumbnail(!!file?.externalLink, file?.originalName ?? '', file?.thumbPath ?? '', file?.path)}
                                            classes="card-img cursor-pointer shadow-lg default-radius-sm"
                                        />
                                    </a>
                                ) : (
                                    <FilePlaceholder file={file} classContent='card-img' hideText/>
                                )}
                            </div>
                            <div className="col-8">
                                <div className="h-100 d-flex flex-column align-items-end justify-content-between">
                                    <div className="d-flex flex-column w-100">
                                        <div className="d-flex justify-content-between">
                                            {!showInputField ? (
                                                <a href={getExternalLink(file?.externalLink) ?? file?.path} target="_blank"
                                                    className="w-75 word-break-word text-dark" rel="noreferrer">
                                                        {t('general.file')} {index + 1}/{state?.attachments?.length} - <span
                                                        className="text-muted">{state?.id}
                                                        </span>
                                                </a>   
                                            ) : (file?.externalLink && externalLink?.id === file?.id) ? 
                                                <TextField
                                                    label={t('general.externalLink')}
                                                    variant="outlined"
                                                    size="small"
                                                    name="link"
                                                    id={String(file?.id)}
                                                    focused={!!file?.externalLink?.length}
                                                    defaultValue={file?.externalLink ?? ''}
                                                    onChange={changeHandler}
                                                    className="w-100"
                                                /> : <span>{file?.id === externalLink?.id ? file?.externalLink : (
                                                    <a href={getExternalLink(file?.externalLink) ?? file?.path} target="_blank"
                                                        className="w-75 word-break-word text-dark" rel="noreferrer">
                                                        {t('general.file')} {index + 1}/{state.attachments?.length} - <span
                                                        className="text-muted">{state?.id}</span>
                                                    </a>
                                                )}</span>
                                            }

                                            {file?.externalLink && (!showInputField ?
                                                    <BsPencilFill onClick={() => {
                                                        setExternalLink(prev => ({
                                                            ...prev,
                                                            link: file?.externalLink ?? '',
                                                            id: file?.id
                                                        }))
                                                        setShowInputField(prev => !prev)
                                                    }}
                                                                  size={15}
                                                                  className="text-primary cursor-pointer"/> :
                                                    <>
                                                        {file?.id === externalLink?.id &&
                                                            <Button variant="primary" className="ml-1"
                                                                    disabled={!externalLink?.link}
                                                                    onClick={() => submitAttachmentHandler(true)}>
                                                                {t('general.button.update')}
                                                            </Button>}
                                                    </>
                                            )}
                                        </div>
                                        {file?.externalLink && !showInputField &&  (
                                              <a href={getExternalLink(file.externalLink) ?? file?.path} target="_blank"
                                                    className="word-break-word" rel="noreferrer">
                                                    {t('general.externalLink')}
                                                </a> 
                                        )}
                                        <span className="text-muted">
                                            {formatDate(file?.createdAt)}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {file?.externalLink && showInputField && file?.id === externalLink?.id &&
                                            <Button variant="outline-primary"
                                                    onClick={handleCancelAttachmentHandler}>
                                                {t('general.button.cancel')}
                                            </Button>}
                                        {file?.isApproved ? (
                                            <FaDownload
                                                onClick={() => downloadFileAPI(`${AGENCY_PLANNER}/planner/attachment/download/${file?.id}`, file?.name?.split('.').at(-1) ?? '', file?.originalName)}
                                                className="text-muted cursor-pointer font-20"/> 
                                        ) : (!showInputField && externalLink?.id !== file?.id) && (
                                            <BsFillTrashFill className="text-muted cursor-pointer font-20"
                                                             onClick={() => {
                                                                 setSelectedFile(file);
                                                             }}/>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>)
            })}

            <ConfirmationModal
                title={t('influencer.planner.deleteImage')}
                description={`${t('influencer.planner.deleteImageDesc')} ${selectedFile?.externalLink ?? selectedFile?.originalName ?? '-'}`}
                action={deleteImageHandler}
                show={!!selectedFile?.id} closeModal={() => setSelectedFile(undefined)}
            />
        </>
    );
};

export default PreviewContentFiles;
